<template>
  <div>
    <Modal v-model:open="openMailModal" :trigger="trigger" footer-background="light" with-close-button with-expand-button full-height>
      <table class="w-full">
        <caption class="sr-only">{{$t('Wcag.ComposeEmail')}}</caption>
        <tr class="text-left text-sm bg-white">
          <th scope="row" class="w-10"><label for="mailTo">{{ $t('Mail.To') }}</label></th>
          <td class="max-w-xl">
            <InputTags
              id="mailTo"
              v-model="recipients"
              :options="recipientsOptions"
              :placeholder="$t('Mail.SelectUsers')"
              allow-others
              @update:modelValue="addTag('recipients', $event)"
              @remove="removeTag('recipients', $event)"
              @click="zIndexFlag=true"/>
          </td>
        </tr>
        <tr class="text-left text-sm bg-white" :class="{'-z-10': zIndexFlag}" @click="zIndexFlag=false">
          <th scope="row" class="w-10"><label for="mailBcc">{{ $t('Mail.Bcc') }}</label></th>
          <td class="max-w-xl pt-1 overflow-scroll">
            <InputTags
              id="mailBcc"
              v-model="bccRecipients"
              :options="bccRecipientsOptions"
              :placeholder="$t('Mail.SelectUsers')"
              allow-others
              @update:modelValue="addTag('bccRecipients', $event)"
              @remove="removeTag('bccRecipients', $event)"/>
          </td>
        </tr>

        <tr>
          <td class="py-2"></td>
        </tr>
        <tr class="border-y border-primary-100">
          <th class="w-10"><label for="mailSubject">{{ $t('Mail.Subject') }}</label></th>
          <td class="py-5" colspan="2">
            <strong>
              <CGInput
                id="mailSubject"
                v-model="subject"
                unstyled-prefix
                unstyled-suffix
              ></CGInput>
            </strong>
          </td>
        </tr>
        <tr>
          <td class="py-3" colspan="2">
            <div v-if="showEditor" class="grid grid-cols-6 gap-x-5">
              <FormGroup :label="$t('Template.Tags')" html-for="tagTemplateSelect" sr-only>
                <CGSelect id="tagTemplateSelect" v-model="selectedTag" :options="placeholderOptions" class="grow grid col-start-1 col-end-6" :placeholder="$t('Template.SelectTagToCopy')"></CGSelect>
              </FormGroup>
              <CGButton :disabled="!selectedTag" variant="outline" @click="copyTemplateTag">
              {{ $t('General.Copy') }}
              </CGButton>
            </div>
            <div class="h-[300px] my-2 border">
                <Editor v-if="showEditor" :code="mailContentSrc" :line-numbers="true" @update:modelValue="onInputMailContent"></Editor>
                <iframe v-else id="templatePreviewEmailIframe" :srcdoc="mailContentSrc" class="h-full w-full" @load="()=>true"></iframe>
            </div>
            <div class="flex justify-end">
              <CGButton variant="outline" class="mb-3" @click="onToggleEditor">
                  <template #prefix>
                      <Icon v-if="showEditor" name="eye" />
                      <Icon v-else name="edit" />
                  </template>
                  {{ showEditor ? $t('Template.ShowPreview') : $t('Template.ShowEditor') }}
              </CGButton>
            </div>
          </td>
        </tr>
      </table>

      <template #footer>
        <div class="flex justify-end w-full space-x-5 pt-5">
          <label v-if="allowAttachment" for="mailAttachment">
            <CGButton variant="ghost" color="secondary">
              <Icon name="attachment" />
            </CGButton>
          </label>
          <input v-if="allowAttachment" id="mailAttachment" type="file" hidden name="attachment" />

          <div class="dark">
            <CGButton ref="confirmModal" small @click="showConfirmSendModal = true;">{{$t('Mail.Send')}}</CGButton>
          </div>
        </div>
      </template>
    </Modal>
    <Modal
      v-model:open="showConfirmSendModal"
      :title="$t('Mail.ConfirmSendMailTitle')"
      :trigger="$refs.confirmModal"
      footer-alignment="right"
      small
    >
      <div class="w-full h-32 text-primary-500 px-4 py-4 text-sm">
        <Txt as='h6' medium style='padding-top: 3%' weight='medium'>
          <span>{{ $t('Mail.ConfirmSendMailMessage', {recipientsCount})}}</span>
        </Txt>
      </div>
      <template #footer>
        <CGButton variant="outline" @click="showConfirmSendModal = false;">
          {{ $t('General.Cancel') }}
        </CGButton>
        <CGButton @click="showConfirmSendModal = false; submitMailDataHandler()">
          {{ $t('General.Confirm') }}
        </CGButton>
      </template>
    </Modal>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

import MailService from '@/services/mail.service.js'
import { EmailLimit, EmailTemplate, CyberGuru } from '@/common/constants.js'

export default defineComponent({
  props: {
    allowAttachment: {
      type: Boolean,
      default: false
    },
    dataItems: {
      type: Object,
      default: () => {
        return {}
      }
    },
    trigger: {
      default: null
    }
  },

  emits: ['showAlert'],

  data() {
    return {
      zIndexFlag: false,
      openMailModal: false,
      recipients: this.dataItems.recipients ? this.dataItems.recipients : [],
      bccRecipients: this.dataItems.bccRecipients ? this.dataItems.bccRecipients : [],
      subject: this.dataItems.subject,
      mailContent: this.dataItems.mailContent,
      showEditor: false,
      mailContentSrc: this.dataItems.mailContent || '',
      placeholderOptions: [],
      selectedTag: null,
      showConfirmSendModal: false,
    }
  },

  computed: {
    recipientsOptions () {
      return this.dataItems.recipientsOptions ? this.dataItems.recipientsOptions : []
    },
    bccRecipientsOptions () {
      return this.dataItems.bccRecipientsOptions ? this.dataItems.bccRecipientsOptions : []
    },
    recipientsCount() {
      return this.recipients[0].label == this.dataItems.allRecipientsLabel ? this.dataItems.recipientsOptions.length - 1 + this.bccRecipients.length : this.recipients.length + this.bccRecipients.length
    }
  },

  watch: {
    openMailModal() {
      if(!this.showEditor) this.previewLg = this.$filters.sanitizeRawHtml(this.mailContentSrc);
      // everytime we open or close the dialog, we clear its data
      this.clearModalData();
    },
    dataItems: {
      handler(newDataItems) {
        this.mailContentSrc = newDataItems.mailContent || '';
      },
      deep: true
    }
  },

  methods: {
    onToggleEditor() {
      if (!this.showEditor) {
        this.mailContentSrc = this.$filters.sanitizeRawHtml(this.mailContentSrc);
      }
      this.showEditor = !this.showEditor;
    },
    checkPlaceholders(text) {
        let cleanText = text.replace(/{{firstname}}/gm, '')
                .replace(/{{display_name}}/gm, '')
                .replace(/{{company_name}}/gm, '');
        return cleanText.match(/{{\.*[a-zA-Z0-9\s][^}]*}}/);
    },
    async checkBodyPlaceholder(code) {
      let invalidPlaceholder = this.checkPlaceholders(code);
      if(invalidPlaceholder && invalidPlaceholder.length > 0) {
          let invalidPlaceholderString = invalidPlaceholder.join(', ');
          this.alertMessage = 'Template.InvalidPlaceholderError';
          this.alertVariant = 'danger';
          this.alertParams = { placeholder: invalidPlaceholderString };
          this.$eventBus.$emit('show-alert', { title: this.$t(this.alertMessage, this.alertParams), variant: this.alertVariant})
          return;
      }
    },
    copyTemplateTag() {
      if (!navigator.clipboard) {
          this.fallbackCopyTemplateTag(this.selectedTag);
          return;
      }
      navigator.clipboard.writeText(this.selectedTag).then(() => {
        this.$eventBus.$emit('show-alert', { title: this.$t('General.CopiedInClipboard', { param: this.selectedTag }), variant: 'success'});
        this.selectedTag = null;
      }, function(err) {
        this.$eventBus.$emit('show-alert', { title: this.$t('General.CopiedInClipboardError', { param: this.selectedTag }), variant: 'danger'})
        console.error('Template Editor: Navigator.clipboard - Could not copy text', err);
      });
    },
    onInputMailContent(mailContentSrc) {
      this.checkBodyPlaceholder(mailContentSrc);
      this.mailContentSrc = this.$filters.sanitizeRawHtml(mailContentSrc);
    },  
    formatRecipients (usersList, allOptionsList) {
      let recipients = []
      if (usersList.length == 1 && !usersList[0].value) { // all recipients option selected
        recipients = [...allOptionsList]
        recipients.shift()
      }
      else {
        recipients = usersList
      }

      recipients = recipients.map(rec=>rec.value).join(',')
      return recipients
    },
    async submitMailDataHandler() {
      const emailContent = EmailTemplate.replace(/{{mail_content}}/gm, this.mailContentSrc)
        .replace(/{{created_by_company_logo}}/gm, this.$company.created_by_company.customization_enabled ? this.$company.created_by_company.logo : (this.$company.customization_enabled ? this.$company.logo : CyberGuru.Logo))
        .replace(/{{created_by_company_displayname}}/gm, this.$company.created_by_company.customization_enabled ? this.$company.created_by_company.displayname : (this.$company.customization_enabled ? this.$company.displayname : CyberGuru.Name));

      const formData = {
        to: {
          email_list: this.formatRecipients(this.recipients, this.recipientsOptions)
        },
        bcc: this.formatRecipients(this.bccRecipients, this.bccRecipientsOptions),
        subject: this.subject,
        html: this.$filters.sanitizeRawHtml(emailContent),
      }

      if (this.recipientsCount > EmailLimit) {
        this.$eventBus.$emit('show-alert', { title: this.$t('Mail.LimitError', {EmailLimit}), variant: 'danger' });
        return;
      }

      this.$methods.invokeFn(MailService.sendMail, this.$t('Mail.ErrorResponse'), formData).then(res => {
        this.$eventBus.$emit('show-alert', { title: this.$t(res.status == 202? 'Mail.SuccessProcessing' : 'Mail.Success'), variant: 'success' });
        this.closeModal();
      }).catch(error=>{
        console.log('submitMailDataHandler ~ error:', error);
      });
    },
    openModal() {
      this.placeholderOptions = [
            { label: this.$i18n.t('General.FirstName'), value: '{{firstname}}' },
            { label: this.$i18n.t('General.Username'), value: '{{display_name}}' },
            { label: this.$i18n.t('Company.CompanyName'), value: '{{company_name}}' },
      ];
      this.placeholderOptions.unshift({ value: null, label: this.$i18n.t('Template.SelectTagToCopy') });
      this.openMailModal = true;
    },
    closeModal() {
      this.openMailModal = false;
    },
    addTag(tagKey, tags) {
      const lastEntry = tags.at(-1)
      if (!lastEntry.value) { // special case
        if (!lastEntry.label) { // user's custom input
          if (!lastEntry.trim()) { // don't allow whitespace
            this[tagKey].pop()
            return
          }
          tags.splice(-1, 1, { // add user's input to selected tags
              label: lastEntry,
              value: lastEntry
            }
          )
        }
        else { // all recipients option selected
          this[tagKey] = [{ 'value': null, 'label': this.dataItems.allRecipientsLabel}] // remove all already selected options
          return
        }
      }
      this[tagKey] = tags.filter((item) => item.value != null); // remove all recipients option
    },
    removeTag(tagKey, tags) {
      this[tagKey] = !tags.length ? [{ 'value': null, 'label': this.dataItems.allRecipientsLabel}] : tags.filter((item) => item.value != null);
    },
    clearModalData() {
      this.recipients = this.dataItems.allRecipientsLabel ? [{'value': null, 'label': this.dataItems.allRecipientsLabel}] : []
      this.bccRecipients = this.dataItems.allBccRecipientsLabel ? [{'value': null, 'label': this.dataItems.allBccRecipientsLabel}] : this.bccRecipients = [];
      this.showEditor = false;
    }
  },
});
</script>