import { apiClient } from '@/plugins/api'

export default {
  getTemplateDetails(companyId, templateId) {
    return companyId? 
      apiClient.get(`phishing/company/${companyId}/template/${templateId}`) : 
      apiClient.get(`phishing/template/${templateId}`)
  },
  getTemplateHints(companyId, templateId, language, searchText, page, numRes, sortBy, sortDesc) {
    const opt = {
      params: {
        searchText: searchText,
        page: page,
        numRes: numRes,
        sortBy: sortBy,
        sortDesc: sortDesc
      }
    }
    return companyId? 
      apiClient.get(`phishing/company/${companyId}/template/${templateId}/hint/${language}`, opt) : 
      apiClient.get(`phishing/template/${templateId}/hint/${language}`, opt)
  },
  updateTemplateHint(companyId, templateId, hintId, payload) {
    return companyId?
      apiClient.put(`phishing/company/${companyId}/template/${templateId}/hint/${hintId}`, payload) :
      apiClient.put(`phishing/template/${templateId}/hint/${hintId}`, payload)
  },
  deleteTemplateHint(companyId, templateId, hintId) {
    return companyId?
      apiClient.delete(`phishing/company/${companyId}/template/${templateId}/hint/${hintId}`) :
      apiClient.delete(`phishing/template/${templateId}/hint/${hintId}`);
  },
  createTemplateHint(companyId, templateId, payload) {
    return companyId?
      apiClient.post(`phishing/company/${companyId}/template/${templateId}/hint`, payload) : 
      apiClient.post(`phishing/template/${templateId}/hint`, payload)
  },
  getHintIcons() {
    return apiClient.get(`phishing/template/hint/icons`)
  },
  getImageRepo() {
    return apiClient.get(`phishing/template/repo`)
  },
  uploadImg(payload) {
    let opt = {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    }
    return apiClient.post(`phishing/template/repo`, payload, opt)
  },
  createTemplate(payload) {
    return apiClient.post(`phishing/template/`, payload)
  },
  copyTemplate(companyId, templateId, payload) {
    return companyId? 
      apiClient.post(`phishing/company/${companyId}/template/${templateId}/copy/`, payload) : 
      apiClient.post(`phishing/template/${templateId}/copy/`, payload)
  },
  deleteTemplate(companyId, templateId) {
    return companyId? 
      apiClient.delete(`phishing/company/${companyId}/template/${templateId}`) : 
      apiClient.delete(`phishing/template/${templateId}`)
  },
  createTemplateContent(companyId, templateId, payload) {
    return companyId? 
      apiClient.post(`phishing/company/${companyId}/template/${templateId}/content`, payload) : 
      apiClient.post(`phishing/template/${templateId}/content`, payload)
  },
  deleteTemplateContent(companyId, templateId, language) {
    return companyId? 
      apiClient.delete(`phishing/company/${companyId}/template/${templateId}/content/${language}`) :
      apiClient.delete(`phishing/template/${templateId}/content/${language}`)
  },
  updateTemplateContent(companyId, templateId, language, payload) {
    return companyId? 
      apiClient.put(`phishing/company/${companyId}/template/${templateId}/content/${language}`, payload) : 
      apiClient.put(`phishing/template/${templateId}/content/${language}`, payload)
  },
  updateTemplate(companyId, templateId, payload) {
    return companyId? 
      apiClient.put(`phishing/company/${companyId}/template/${templateId}`, payload) : 
      apiClient.put(`phishing/template/${templateId}`, payload)
  },
  searchAllTemplates(searchText, scope, companyId, type, deleted, sortBy, sortDesc, hasAttachment, language, page, numRes) {
    const opt = {
      params: {
        searchText,
        scope,
        type,
        deleted,
        language,
        sortBy: sortBy,
        sortDesc: sortDesc,
        hasAttachment: hasAttachment,
        companyId,
        page,
        numRes
      }
    }
    return apiClient.get(`phishing/template`, opt)
  },
  changeDeletedStatus(companyId, templateId, status) {
    let opt = { deleted: status }
    return companyId? 
      apiClient.put(`phishing/company/${companyId}/template/${templateId}/status`, opt) :
      apiClient.put(`phishing/template/${templateId}/status`, opt)
  },
  alignRankings() {
    return apiClient.get(`phishing/template/align`)
  },
  importTemplate(companyId, payload) {
    let opt = {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    }
    return companyId? 
      apiClient.post(`/phishing/company/${companyId}/template/import`, payload, opt) :
      apiClient.post('/phishing/template/import', payload, opt)
  },
  exportTemplates(companyId) {
    return companyId?
      apiClient.get(`/phishing/company/${companyId}/template/export`) : 
      apiClient.get('/phishing/template/export')
  },
  exportTemplate(companyId, templateId) {
    return companyId? 
      apiClient.get(`phishing/company/${companyId}/template/${templateId}/export`) :
      apiClient.get(`phishing/template/${templateId}/export`)
  },
  testAttack(templateCompanyId, templateId, targetEmail, targetName, targetNumber, templateType, previewLanguage, companyId, singleLanguage) {
    const payload = {
      target_email: targetEmail,
      target_name: targetName,
      target_number: targetNumber,
      template_type: templateType,
      language: previewLanguage,
      company_id: companyId,
      single_language: singleLanguage
    }
    return templateCompanyId? 
      apiClient.post(`phishing/company/${templateCompanyId}/template/${templateId}/test`, payload) :
      apiClient.post(`phishing/template/${templateId}/test`, payload)
  },
  /**
   * Return template landing page content of the given language
   * @param {*} templateCompanyId - companyId of the template
   * @param {*} templateId 
   * @param {*} language - landing page language
   * @param {*} defLandingPageCompanyId - if the template does not have landing page set, it will return the content of the default landing page of the selected company
   */
  getTemplateLandingPage(templateCompanyId, templateId, language, defLandingPageCompanyId) {
    const opt = { params: {companyId: defLandingPageCompanyId}};
    return templateCompanyId?
      apiClient.get(`/phishing/company/${templateCompanyId}/template/${templateId}/landingpage/content/${language}`, opt) : 
      apiClient.get(`/phishing/template/${templateId}/landingpage/content/${language}`, opt);
  },

  /** TEMPLATE ATTACHMENT APIs */
  getTemplateAttachments(companyId, scope, page, numRes, sortBy, sortDesc) {
    const opt = {
      params: {
        companyId: companyId,
        scope: scope,
        page: page,
        numRes: numRes,
        sortBy: sortBy,
        sortDesc: sortDesc
      }
    }
    return apiClient.get(`phishing/attachment`, opt)
  },
  createTemplateAttachment(companyId, body) {
    const query = companyId? `?companyId=${companyId}` : '';
    return apiClient.post(`phishing/attachment${query}`, body, { headers: { 'Content-Type': 'multipart/form-data;' } });
  },
  updateTemplateAttachment(companyId, attachmentId, body) {
    return companyId? 
      apiClient.put(`phishing/company/${companyId}/attachment/${attachmentId}`, body, { headers: { 'Content-Type': 'multipart/form-data;' } }) : 
      apiClient.put(`phishing/attachment/${attachmentId}`, body, { headers: { 'Content-Type': 'multipart/form-data;' } });
  },
  copyTemplateAttachment(companyId, attachmentId, body) {
    return companyId? 
      apiClient.post(`phishing/company/${companyId}/attachment/${attachmentId}/copy`, body) : 
      apiClient.post(`phishing/attachment/${attachmentId}/copy`, body);
  },
  deleteAttachment(companyId, attachmentId, language) {
    return companyId? 
      apiClient.delete(`/phishing/company/${companyId}/attachment/${attachmentId}`, { params: { language } }) :
      apiClient.delete(`/phishing/attachment/${attachmentId}`, { params: { language } });
  },

  /** MULTIMEDIA APIs */
  getMediaResources(companyId, scope, page, numRes, sortBy, sortDesc) {
    const opt = {
      params: {
        companyId: companyId,
        scope: scope,
        page: page,
        numRes: numRes,
        sortBy: sortBy,
        sortDesc: sortDesc
      }
    }
    return apiClient.get(`phishing/media`, opt);
  },
  createMediaResource(companyId, body) {
    const query = companyId? `?companyId=${companyId}` : '';
    return apiClient.post(`phishing/media${query}`, body, { headers: { 'Content-Type': 'multipart/form-data;' } });
  },
  updateMediaResource(companyId, mediaId, body) {
    return companyId?
      apiClient.put(`phishing/company/${companyId}/media/${mediaId}`, body, { headers: { 'Content-Type': 'multipart/form-data;' } }) : 
      apiClient.put(`phishing/media/${mediaId}`, body, { headers: { 'Content-Type': 'multipart/form-data;' } });
  },
  copyMediaResource(companyId, mediaId, body) {
    return companyId? 
      apiClient.post(`phishing/company/${companyId}/media/${mediaId}/copy`, body) : 
      apiClient.post(`phishing/media/${mediaId}/copy`, body);
  },
  deleteMediaResource(companyId, mediaId, language, uri) {
    return companyId? 
      apiClient.delete(`/phishing/company/${companyId}/media/${mediaId}`, { params: { language, uri } }) :
      apiClient.delete(`/phishing/media/${mediaId}`, { params: { language, uri } });
  }
}