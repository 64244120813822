const administrationRoutes = [
  {
    path: '',
    component: () => import('../views/csm/Dashboard.vue'),
  },
  {
    path: 'stats/:companyId',
    component: () => import('../views/admin/UsersStats.vue'),
  },
  {
    path: 'releases/:companyId',
    component: () => import('../views/admin/Releases.vue'),
  },
  {
    path: 'company',
    component: () => import('../views/admin/CompanyList.vue'),
  },
  {
    path: 'roles',
    component: () => import('../views/admin/Roles.vue'),
  },
  {
    path: 'report',
    component: () => import('../views/admin/Report.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/admin/ReportOverview.vue'),
      },
      {
        path: 'report-awareness',
        component: () => import('../views/admin/ReportAwareness.vue'),
      },
      {
        path: 'report-channel',
        component: () => import('../views/admin/ReportChannel.vue'),
      },
      {
        path: 'report-phishing',
        component: () => import('../views/admin/ReportPhishing.vue'),
      },
      {
        path: 'report-remediation',
        component: () => import('../views/admin/ReportRemediation.vue'),
      },
    ]
  },
  {
    path: 'content-management',
    component: () => import('../views/admin/ContentMgmt.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/admin/ModuleList.vue')
      },
      {
        path: 'path',
        component: () => import('../views/admin/ModulePath.vue'),
      },
      {
        path: 'badge',
        component: () => import('../views/admin/Badge.vue')
      },
      {
        path: 'reserved',
        component: () => import('../views/admin/ReservedArea.vue')
      },
      {
        path: 'landing-pages',
        component: () => import('../views/admin/LandingPages.vue'),
      },
      {
        path: 'template-management',
        component: () => import('../views/admin/TemplateMgmt.vue'),
      },
      {
        path: 'attachment-management',
        component: () => import('../views/admin/Attachments.vue')
      },
      {
        path: 'media-management',
        component: () => import('../views/admin/MediaResources.vue')
      }
    ]
  },
  {
    path: 'account',
    component: () => import('../views/csm/AccountList.vue')
  },
  {
    path: 'account/:accountId/:companyId/registration',
    component: () => import('../views/admin/UserRegistrationList.vue')
  },
  {
    path: 'release-management',
    component: () => import('../views/admin/ReleaseMgmt.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/admin/Campaigns.vue'),
      },
      {
        path: 'remediations',
        component: () => import('../views/admin/Remediations.vue'),
      }
    ]
  },
  {
    path: 'release-management/company/:companyId/campaigns/:campaignId',
    component: () => import('../views/admin/CampaignDetails.vue'),
  },
  {
    path: 'company/:companyId',
    component: () => import('../views/admin/Company.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/admin/CompanyInfo.vue'),
        props: true
      },
      {
        path: 'authentication',
        component: () => import('../views/admin/CompanyAuthentication.vue'),
      },
      {
        path: 'platform',
        component: () => import('../views/admin/CompanyPlatform.vue'),
        props: true
      },
      {
        path: 'welcome',
        component: () => import('../views/admin/CompanyWelcomeMessage.vue'),
        props: true
      },
      {
        path: 'certificate',
        component: () => import('../views/admin/CompanyCertificate.vue'),
        props: true
      },
      {
        path: 'scim',
        component: () => import('../views/admin/ScimConfig.vue'),
      },
      {
        path: 'account',
        component: () => import('../views/admin/AccountList.vue'),
      },
      {
        path: 'account/:accountId/registration',
        component: () => import('../views/admin/UserCompanyRegistrationList.vue')
      },
      {
        path: 'account/:accountId',
        component: () => import('../views/admin/UserPath.vue')
      },
      {
        path: 'account/:accountId/level/:levelId',
        component: () => import('../views/admin/UserPathDetail.vue')
      },
      {
        path: 'phishing',
        component: () => import('../views/admin/Phishing.vue'),
      },
      {
        path: 'organization',
        component: () => import('../views/admin/OrganizationList.vue'),
        props: true
      },
      {
        path: 'teamleader',
        component: () => import('../views/admin/AssignTeamLead.vue'),
      },
      {
        path: 'license',
        component: () => import('../views/admin/License.vue'),
      },
      {
        path: 'license/:licenseId/account',
        component: () => import('../views/admin/LicenseAccountList.vue'),
      },
      {
        path: 'student-caring',
        component: () => import('../views/admin/StudentCaring.vue'),
      },
      {
        path: 'student-caring/event/:eventId?',
        component: () => import('../views/admin/StudentCaringEvent.vue'),
      },
      {
        path: 'release-frequency',
        component: () => import('../views/admin/ReleaseFrequency.vue'),
      },
      {
        path: 'release-frequency/:serviceId/calendar',
        component: () => import('../views/admin/ReleaseFrequencyCalendar.vue'),
      },
      {
        path: 'championships',
        component: () => import('../views/admin/Championships.vue'),
      },
      {
        path: 'options',
        component: () => import('../views/admin/Options.vue'),
      },
      {
        path: ':serviceId(awareness|channel|additional-contents)',
        component: () => import('../views/admin/ServicePath.vue'),
      },
      {
        path: ':serviceId(awareness|channel|additional-contents)/:level_uuid',
        component: () => import('../views/admin/ServicePathDetail.vue'),
      },
    ],
  },
  {
    path: 'path-management/:level_uuid',
    component: () => import('../views/admin/ModulePathDetail.vue'),
  },
  {
    path: 'settings',
    component: () => import('../views/admin/Settings.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/admin/Roles.vue')
      },
      {
        path: 'options',
        component: () => import('../views/admin/CompanyOptions.vue')
      },
      {
        path: 'awareness-options',
        component: () => import('../views/admin/AwarenessOptions.vue'),
      }
    ]
  },
]

const routes = [
  {
    path: '/admin',
    component: () => import('../views/admin/Admin.vue'),
    children: administrationRoutes,
  },
  {
    path: '/csm',
    component: () => import('../views/admin/Admin.vue'),
    children: administrationRoutes,
  },
  {
    path: '/msp',
    component: () => import('../views/admin/Admin.vue'),
    children: administrationRoutes,
  },
  {
    path: '/cm',
    component: () => import('../views/admin/Admin.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/cm/Dashboard.vue'),
      },
      {
        path: 'account',
        component: () => import('../views/csm/AccountList.vue')
      },
      {
        path: 'content-management',
        component: () => import('../views/admin/ContentMgmt.vue'),
        children: [
          {
            path: '',
            component: () => import('../views/admin/ModuleList.vue')
          },
          {
            path: 'path',
            component: () => import('../views/admin/ModulePath.vue'),
          },
          {
            path: 'badge',
            component: () => import('../views/admin/Badge.vue')
          },
          {
            path: 'landing-pages',
            component: () => import('../views/admin/LandingPages.vue'),
          },
          {
            path: 'template-management',
            component: () => import('../views/admin/TemplateMgmt.vue'),
          },
          {
            path: 'attachment-management',
            component: () => import('../views/admin/Attachments.vue')
          },
          {
            path: 'media-management',
            component: () => import('../views/admin/MediaResources.vue')
          }
        ]
      },
      {
        path: 'path-management/:level_uuid',
        component: () => import('../views/admin/ModulePathDetail.vue'),
      },
    ],
  },
  {
    path: '/companyadmin',
    component: () => import('../views/admin/Admin.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/csm/Dashboard.vue'),
      },
      {
        path: 'stats/:companyId',
        component: () => import('../views/admin/UsersStats.vue'),
      },
      {
        path: 'report',
        component: () => import('../views/admin/Report.vue'),
        children: [
          {
            path: '',
            component: () => import('../views/admin/ReportOverview.vue'),
          },
          {
            path: 'report-awareness',
            component: () => import('../views/admin/ReportAwareness.vue'),
          },
          {
            path: 'report-channel',
            component: () => import('../views/admin/ReportChannel.vue'),
          },
          {
            path: 'report-phishing',
            component: () => import('../views/admin/ReportPhishing.vue'),
          },
          {
            path: 'report-remediation',
            component: () => import('../views/admin/ReportRemediation.vue'),
          },
        ]
      },
      {
        path: 'company/:companyId',
        component: () => import('../views/admin/Company.vue'),
        children: [
          {
            path: '',
            component: () => import('../views/admin/CompanyInfo.vue'),
            props: true
          },
          {
            path: 'authentication',
            component: () => import('../views/admin/CompanyAuthentication.vue'),
          },
          {
            path: 'platform',
            component: () => import('../views/admin/CompanyPlatform.vue'),
            props: true
          },
          {
            path: 'welcome',
            component: () => import('../views/admin/CompanyWelcomeMessage.vue'),
            props: true
          },
          {
            path: 'certificate',
            component: () => import('../views/admin/CompanyCertificate.vue'),
            props: true
          },
          {
            path: 'scim',
            component: () => import('../views/admin/ScimConfig.vue'),
          },
          {
            path: 'account',
            component: () => import('../views/admin/AccountList.vue'),
          },
          {
            path: 'account/:accountId/registration',
            component: () => import('../views/admin/UserCompanyRegistrationList.vue')
          },
          {
            path: 'phishing',
            component: () => import('../views/admin/Phishing.vue'),
          },
          {
            path: 'organization',
            component: () => import('../views/admin/OrganizationList.vue'),
            props: true
          },
          {
            path: 'teamleader',
            component: () => import('../views/admin/AssignTeamLead.vue'),
          },
          {
            path: 'license',
            component: () => import('../views/admin/License.vue'),
          },
          {
            path: 'license/:licenseId/account',
            component: () => import('../views/admin/LicenseAccountList.vue'),
          },
          {
            path: 'student-caring',
            component: () => import('../views/admin/StudentCaring.vue'),
          },
          {
            path: 'student-caring/event/:eventId?',
            component: () => import('../views/admin/StudentCaringEvent.vue'),
          },
          {
            path: 'release-frequency',
            component: () => import('../views/admin/ReleaseFrequency.vue'),
          },
          {
            path: 'release-frequency/:serviceId/calendar',
            component: () => import('../views/admin/ReleaseFrequencyCalendar.vue'),
          },
          {
            path: 'options',
            component: () => import('../views/admin/Options.vue'),
          },
          {
            path: 'account/:accountId',
            component: () => import('../views/admin/UserPath.vue')
          },
          {
            path: ':serviceId(awareness|channel|additional-contents)',
            component: () => import('../views/admin/ServicePath.vue'),
          },
          {
            path: ':serviceId(awareness|channel|additional-contents)/:level_uuid',
            component: () => import('../views/admin/ServicePathDetail.vue'),
          },
          {
            path: 'championships',
            component: () => import('../views/admin/Championships.vue'),
          },
        ],
      },
      {
        path: 'content-management',
        component: () => import('../views/admin/ContentMgmt.vue'),
        children: [
          {
            path: 'reserved',
            component: () => import('../views/admin/ReservedArea.vue')
          },
          {
            path: 'landing-pages',
            component: () => import('../views/admin/LandingPages.vue'),
          },
          {
            path: 'template-management',
            component: () => import('../views/admin/TemplateMgmt.vue'),
          },
          {
            path: 'attachment-management',
            component: () => import('../views/admin/Attachments.vue')
          },
          {
            path: 'media-management',
            component: () => import('../views/admin/MediaResources.vue')
          }
        ]
      },
      {
        path: 'release-management',
        component: () => import('../views/admin/ReleaseMgmt.vue'),
        children: [
          {
            path: '',
            component: () => import('../views/admin/Campaigns.vue'),
          },
          {
            path: 'remediations',
            component: () => import('../views/admin/Remediations.vue'),
          }
        ]
      },
      {
        path: 'release-management/company/:companyId/campaigns/:campaignId',
        component: () => import('../views/admin/CampaignDetails.vue'),
      },
      {
        path: 'path-management/:level_uuid',
        component: () => import('../views/admin/ModulePathDetail.vue'),
      },
      {
        path: 'certificates',
        component: () => import('../views/admin/Certificates.vue'),
      },
    ],
  },
  {
    path: '/supervisor',
    component: () => import('../views/admin/Admin.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/admin/Dashboard.vue'),
      },
      {
        path: 'stats/:companyId',
        component: () => import('../views/admin/UsersStats.vue'),
      },
      {
        path: 'report-phishing',
        component: () => import('../views/admin/ReportPhishing.vue'),
      },
      {
        path: 'report-awareness',
        component: () => import('../views/admin/ReportAwareness.vue'),
      },
      {
        path: 'report-channel',
        component: () => import('../views/admin/ReportChannel.vue'),
      },
      {
        path: 'report',
        component: () => import('../views/admin/Report.vue'),
        children: [
          {
            path: '',
            component: () => import('../views/admin/ReportOverview.vue'),
          },
          {
            path: 'report-awareness',
            component: () => import('../views/admin/ReportAwareness.vue'),
          },
          {
            path: 'report-channel',
            component: () => import('../views/admin/ReportChannel.vue'),
          },
          {
            path: 'report-phishing',
            component: () => import('../views/admin/ReportPhishing.vue'),
          },
          {
            path: 'report-remediation',
            component: () => import('../views/admin/ReportRemediation.vue'),
          },
        ]
      },
      {
        path: 'certificates',
        component: () => import('../views/admin/Certificates.vue'),
      },
    ],
  },
  {
    path: '/teamleader',
    component: () => import('../views/teamleader/TeamLeader.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/teamleader/Dashboard.vue'),
      },
      {
        path: 'stats/:companyId/:team(.*)',
        component: () => import('../views/admin/UsersStats.vue'),
      },
      {
        path: 'report',
        component: () => import('../views/admin/Report.vue'),
        children: [
          {
            path: '',
            component: () => import('../views/admin/ReportOverview.vue'),
          },
          {
            path: 'report-awareness',
            component: () => import('../views/admin/ReportAwareness.vue'),
          },
          {
            path: 'report-channel',
            component: () => import('../views/admin/ReportChannel.vue'),
          },
          {
            path: 'report-phishing',
            component: () => import('../views/admin/ReportPhishing.vue'),
          },
        ]
      },
    ],
  },
  {
    path: '/student',
    component: () => import('../views/student/Student.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/student/Dashboard.vue'),
      },
      {
        path: 'progress',
        component: () => import('../views/student/Progress.vue'),
      },
      {
        path: 'modules',
        component: () => import('../views/student/Modules.vue'),
      },
      {
        path: 'reserved-area',
        component: () => import('../views/student/ReservedArea.vue'),
      },
      {
        path: 'acknowledgment',
        component: () => import('../views/student/Acknowledgment.vue'),
        children: [
          {
            path: '',
            component: () => import('../views/student/Certificates.vue')
          },
          {
            path: 'badges',
            component: () => import('../views/student/Badge.vue'),
          },
        ]
      },
    ],
  },
  {
    path: '/profile',
    component: () => import('../views/Profile.vue'),
  },
  {
    path: '/knowledge-base',
    component: () => import('../views/KnowledgeBase.vue'),
  },
  {
    path: '/help',
    component: () => import('../views/Help.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/Rules.vue')
      },
      {
        path: 'privacy',
        component: () => import('../views/Privacy.vue'),
      },
      {
        path: 'kb',
        component: () => import('../views/KnowledgeBase.vue')
      },
    ]
  }
]

export const defaultPaths = ['/profile', '/help', '/help/privacy', '/help/kb']
export default routes
