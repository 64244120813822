<template>
  <div v-if="totalItems > 0" :class="{ 'flex flex-col md:flex-row items-center md:float-end': true, disabled }">
    <div class="hidden md:block mr-1">
      <CGSimpleSelect
        :id="`pageItems${uuid}`"
        :modelValue="`${itemsPerPage}`"
        :label="$t('Analytics.Table.ItemsPerPage')"
        :options="pageItemsOptions"
        labelClass="!text-sm !font-normal"
        auto-width
        @update:modelValue="(limit) => selectPage(1, +limit)"
      />
    </div>

    <nav :aria-label="context || $t('Wcag.Pagination')" class="w-full md:w-auto">
      <ul class="pagination mx-4 justify-content-center">
        <li class="page-item" :class="{ 'hidden': onFirstPage }">
          <button class="page-link relative" :disabled="disabled || onFirstPage" @click="selectPage(1, itemsPerPage)">
            <span class="sr-only">{{ $t('Wcag.PaginationFirstPage') }}</span>
            <Icon name="chevronDoubleRight" class="rotate-180 align-middle" />
          </button>
        </li>
        <li class="page-item" :class="{ 'hidden': onFirstPage }">
          <button class="page-link relative" :disabled="disabled || onFirstPage" @click="selectPage(page - 1, itemsPerPage)">
            <span class="sr-only">{{ $t('Wcag.PaginationPrevPage') }}</span>
            <Icon name="chevronRight" class="rotate-180 align-middle" />
          </button>
        </li>
        <li v-for="num in pageNumbers" :key="num" :class="{ active: num === page, 'page-item w-full md:w-auto' : true, 'hidden lg:block': num !== page }">
          <button class="page-link relative w-full md_w-auto" :disabled="disabled" @click="() => { num !== page? selectPage(num) : () => {} }">
            <span class="sr-only">{{ $t('Wcag.Page') }}</span>
            {{ num }}
          </button>
        </li>
        <li class="page-item" :class="{ 'hidden': onLastPage }">
          <button class="page-link relative" :disabled="disabled || onLastPage" @click="selectPage(page + 1, itemsPerPage)">
            <span class="sr-only">{{ $t('Wcag.PaginationNextPage') }}</span>
            <Icon name="chevronRight" class="align-middle" />
          </button>
        </li>
        <li class="page-item" :class="{ 'hidden': onLastPage }">
          <button class="page-link relative" :disabled="disabled || onLastPage" @click="selectPage(numPages, itemsPerPage)">
            <span class="sr-only">{{ $t('Wcag.PaginationLastPage') }}</span>
            <Icon name="chevronDoubleRight" class="align-middle" />
          </button>
        </li>
      </ul>
    </nav>

    <span v-if="totalItems > 0" class="ml-1 text-sm hidden md:block">
      {{ $t("General.Pagination", { start: visibleItemsStart, end: visibleItemsEnd, total: totalItems }) }}
    </span>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';

let uuid = 0;

export default defineComponent({
  props: {
    page: {
      type: Number,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      default: 20
    },
    totalItems: {
      type: Number,
      default: 0
    },
    maxButtonsPerSide: {
      type: Number,
      default: 2
    },
    disabled: {
      type: Boolean,
      default: false
    },
    context: {
      type: String,
      required: false
    },
    perPageOptionValues: {
      type: Array,
      default: () => ['5', '10', '20', '50', '100']
    }
  },
  data() {
    return {
      pageItemsOptions: this.perPageOptionValues.map((n) => ({ value: n, label: n }))
    }
  },
  beforeCreate() {
    this.uuid = uuid.toString();
    uuid += 1;
  },
  setup(props) {
    const visibleItemsStart = computed(() => (props.page - 1) * props.itemsPerPage + 1);
    const visibleItemsEnd = computed(() => Math.min(props.page * props.itemsPerPage, props.totalItems));

    const numPages = computed(() => Math.ceil(props.totalItems / props.itemsPerPage));
    const onFirstPage = computed(() => props.page === 1);
    const onLastPage = computed(() => props.page === numPages.value);

    const pageNumbers = computed(() => {
      const moreThanMin = (num) => num >= props.page - props.maxButtonsPerSide || num >= numPages.value - props.maxButtonsPerSide * 2;
      const lessThenMax = (num) => num <= props.page + props.maxButtonsPerSide || num <= props.maxButtonsPerSide * 2 + 1;
      return Array.from(Array(numPages.value + 1).keys()).slice(1)
        .filter(num => moreThanMin(num) && lessThenMax(num));
    });

    return {
      numPages,
      onFirstPage,
      onLastPage,
      pageNumbers,
      visibleItemsStart,
      visibleItemsEnd
    };
  },
  methods: {
    selectPage(page, perPage = this.itemsPerPage) {
      this.$emit('page-change', page, perPage);
    }
  }
});
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  @apply px-4 h-10;
  @apply text-primary-500;
  @apply bg-white;
  @apply border-light border-[1px] border-r-0;

  &.active,
  &:focus {
    @apply bg-transparent;
  }

  .disabled & {
    @apply bg-primary-100 text-primary-300 cursor-not-allowed;
  }
}

.page-link:hover {
  z-index: 2;
  color: unset;
  background-color: unset;

  .disabled & {
    @apply bg-primary-100 text-primary-300 cursor-not-allowed;
  }
}

.page-item.active .page-link {
  @apply md:border-soft-blue-500 md:ring-soft-blue-500 bg-transparent;
  @apply border-r-[1px];
  @apply font-bold;
}

.page-item:last-child .page-link {
  @apply border-r-[1px];
}
</style>