<template>
  <span
      :class="[
      'inline-flex items-center rounded-md px-2 py-1 text-xs ring-1 ring-inset font-bold',
      bgColor, messageColor, ringColor
    ]"
  >
    <Icon v-if="showIcon" :name="iconName" :size="16" class="w-4 h-4 mr-1 leading-3" />
    <span class="leading-3 mt-1">{{ text }}</span>
  </span>
</template>

<script>
import {defineComponent} from 'vue';
import $methods from "@/common/methods";

const stateStyles = {
  success: {
    bgColor: 'bg-green-50',
    textColor: 'text-green-800',
    ringColor: 'ring-green-600/20',
    icon: 'check',
  },
  warning: {
    bgColor: 'bg-yellow-50',
    textColor: 'text-yellow-800',
    ringColor: 'ring-yellow-600/20',
    icon: 'warning',
  },
  danger: {
    bgColor: 'bg-red-50',
    textColor: 'text-red-800',
    ringColor: 'ring-red-600/20',
    icon: 'alert',
  },
  info: {
    bgColor: 'bg-blue-50',
    textColor: 'text-blue-800',
    ringColor: 'ring-blue-600/20',
    icon: 'info',
  },
  unknown: {
    bgColor: 'bg-gray-50',
    textColor: 'text-gray-800',
    ringColor: 'ring-gray-600/20',
    icon: 'help',
  }
};

export default  defineComponent({
  props: {
    message: {
      type: String,
      required: true,
    },
    state: {
      type: String,
      default: 'unknown',
      validator: value => ['success', 'warning', 'danger', 'info', 'unknown'].includes(value),
    },
    showIcon: {
      type: Boolean,
      default: false,
    }
  },
  setup(props)  {
    const currentState = stateStyles[props.state];

    const bgColor =  currentState.bgColor;
    const messageColor = currentState.textColor;
    const ringColor = currentState.ringColor;
    const iconName = props.showIcon ? currentState.icon : null;
    const text = $methods.capitalizeWords(props.message);

    return {
      bgColor,
      messageColor,
      ringColor,
      iconName,
      text
    };
  },
})
</script>