<template>
  <div
      ref="dateRangePicker"
      class="date-range-picker"
      @mouseleave="handleMouseLeave"
  >
    <vue-datepicker
        v-model="dateRange"
        :range="true"
        :position="position"
        :lang="lang"
        :format="dateFormat"
        :show-clear-button="showClearButton"
        :placeholder="placeholder"
        @update:modelValue="handleRangeSelected"
        @reset="handleResetRange"
    ></vue-datepicker>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import VueDatepicker from 'vue-datepicker-ui';
import 'vue-datepicker-ui/lib/vuedatepickerui.css';

export default defineComponent({
  name: 'DateRangePicker',

  components: {
    VueDatepicker
  },

  props:{
    ranges: {
      type: Array,
      default:() => [new Date(new Date().getTime() - 7 * 24 * 60 * 60 *1000), new Date()]
    }
  },
  emits: ['range-selected', 'reset-range-selected'],
  data() {
    return {
      dateRange: [],
      dateFormat: 'YYYY-MM-DD',
      showClearButton: true,
      position: 'center',
      lang: this.$i18n.locale,
      placeholder: this.$t('General.SelectPeriod')
    };
  },

  methods: {
    handleRangeSelected() {
      this.$emit('range-selected', this.dateRange);
    },
    handleResetRange(){
      this.$emit('reset-range-selected',this.dateRange)
    },
    handleMouseLeave() {
      if (this.dateRange.length > 0 && this.dateRange.some(element => !element)) {
        this.dateRange = []
      }
    }
  },
});
</script>

<style lang="scss" global>
.date-range-picker {
  display: inline-block;
}

.v-calendar {
  .content {
    position: fixed !important;
  }
  .input-field svg.datepicker {
    fill: rgb(31 21 132 / var(--tw-bg-opacity)) !important;
  }
  .calendar{
    .days{
      .day.selectedRange {
        background: rgba(185, 214, 246, 0.99) !important;
        color: #000000 !important;
      }
    }
  }
}
.v-calendar .calendar .days .day.selectedDate .number {
  background: #1f1584 !important;
  color: #ffffff !important;
  font-weight: bolder;
}
.v-calendar .calendar .selected-field .viewButton {
  border: 0;
  width: 100%;
  padding: 10px;
  color: #000 !important;
  font-size: var(--v-calendar-view-button-font-size);
  font-weight: var(--v-calendar-view-button-font-weight);
  margin-right: 10px;
  margin-left: 10px;
  background: transparent;
  cursor: pointer;
}
.v-calendar .calendar .selected-field .prevDateButton::after {
  border-right: 10px solid #000 !important;
}
.v-calendar .calendar .selected-field .nextDateButton::after {
  border-left: 10px solid #000 !important;
}
.v-calendar .calendar .days .day.name {
  pointer-events: none;
  font-size: var(--v-calendar-day-name-font-size);
  font-weight: var(--v-calendar-day-name-font-weight);
  color: #000 !important;
  width: auto;
}
.v-calendar .calendar .days .day {
  padding: 0;
  margin-right: 10px;
  font-size: var(--v-calendar-day-font-size);
  font-weight: var(--v-calendar-day-font-weight);
  color: #090909 !important;
  width: 100%;
  text-align: left;
}
.v-calendar .input-field input {
  padding-left: 40px;
  padding-right: 20px;
  font-size: var(--v-calendar-input-font-size);
  min-width: inherit;
  height: 50px;
  border-radius: 6px;
  border: var(--v-calendar-input-border);
  background-color: #fff;
  color: #666 !important;
  font-weight: inherit;
}
.v-calendar .input-field input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #242629;
}
.v-calendar .calendar .days .day.disabledDate {
  pointer-events: none;
  color: #707079 !important;
}
.v-calendar .calendar .days .day.selectedDate .number {
  background: #1f1584 !important;
  color: #ffffff !important;
  font-weight: bolder;
}
.v-calendar .calendar .selected-field .viewButton {
  border: 0;
  width: 100%;
  padding: 10px;
  color: #000 !important;
  font-size: var(--v-calendar-view-button-font-size);
  font-weight: var(--v-calendar-view-button-font-weight);
  margin-right: 10px;
  margin-left: 10px;
  background: transparent;
  cursor: pointer;
}
.v-calendar .calendar .selected-field .prevDateButton::after {
  border-right: 10px solid #000 !important;
}
.v-calendar .calendar .selected-field .nextDateButton::after {
  border-left: 10px solid #000 !important;
}
.v-calendar .calendar .days .day.name {
  pointer-events: none;
  font-size: var(--v-calendar-day-name-font-size);
  font-weight: var(--v-calendar-day-name-font-weight);
  color: #000 !important;
  width: auto;
}
.v-calendar .calendar .days .day {
  padding: 0;
  margin-right: 10px;
  font-size: var(--v-calendar-day-font-size);
  font-weight: var(--v-calendar-day-font-weight);
  color: #090909 !important;
  width: 100%;
  text-align: left;
}
.v-calendar .input-field input {
  padding-left: 40px;
  padding-right: 20px;
  font-size: var(--v-calendar-input-font-size);
  min-width: inherit;
  height: 50px;
  border-radius: 6px;
  border: var(--v-calendar-input-border);
  background-color: #fff;
  color: #666 !important;
  font-weight: inherit;
}
.v-calendar .input-field input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #242629;
}
.v-calendar .calendar .days .day.disabledDate {
  pointer-events: none;
  color: #707079 !important;
}
.v-calendar .calendar .days .day.selectedDate .number {
  background: #1f1584 !important;
  color: var(--v-calendar-active-text-color);
}
</style>