<template>
  <div
    v-if='!readonly'
    ref="selectEl"
    :class="{
      'select-wrapper': true,
      disabled,
    }"
    @keyup.esc="toggleOpen(false)"
    @focusout.capture='onFocusOut($event)'
    @keyup.down.capture="$refs.selectOptionEl0[0].focus()"
  >
    <InputWrapper
      :errored="errored"
      :error-message="errorMessage"
      :label="label"
      :disabled="disabled"
      :active="open"
      :style="{ 'min-width' : filterWidth + 'px', 'max-width': maximumWidth ? maxWidthValue + 'px' : '' }"
      unstyled-prefix
      unstyled-suffix
      >
      <template v-if="$slots.prefix" #prefix>
        <slot name="prefix" />
      </template>
      <template #suffix>
        <button class="flex w-10 h-full items-center" @click="toggleOpen(true)">
          <Icon name="chevronDown" class="text-violet-700"/>
        </button>
        <button v-if="modelValue && erasable && !required" class="flex h-full w-10 items-center" :aria-label="$t('General.Clear')" @click="!this.disabled && $emit('update:modelValue', null);">
          <Icon name="close" class="text-violet-700"/>   
        </button>
      </template>
      <div class="select" 
        @mousedown.capture="toggleOpen(true)"
        @keyup.enter="toggleOpen(true)"
        @keyup.space="toggleOpen(true)"
        @click="toggleOpen(true)"
      >
        <select
          :modelValue="modelValue" 
          :name="name" 
          :multiple="multiple" 
          :aria-expanded="String(open)"
          :aria-labelledby="'selectMenuId'+id"
          />
        <input
          v-if="showFilter"
          :id="id" 
          ref="selectFilterEl"
          v-model="filterValue"
          class="w-full border-0 outline-0 outline-none"
          :placeholder="placeholder || $t('General.Filter')"
          :class="{ hide: !open && (!showPlaceholder || modelValue || filterValue) }"
          :required="required"
          role="combobox"
          :aria-controls="'selectMenuId'+id"
          aria-autocomplete="list"
          :aria-expanded="String(open)"
        />
        <span class="select-value" :class="{ hide: showFilter && open}" :aria-current="!open">
          {{ text }}
        </span>
      </div>
    </InputWrapper>
    <div
      :id="'selectMenuId'+id"
      ref="selectMenuEl"
      :style="{'max-width': maximumWidth ? maxWidthValue + 'px' : ''}"
      :class="{
        'select-menu': true,
        'v-auto-width': autoWidth,
        'v-maximum-width': maximumWidth,
        hide: !open,
        open
      }"
      role="listbox"
      :aria-label="context || $parent.label || label"
    >
      <div
        v-for="(option, i) in filteredOptions"
        :ref="`selectOptionEl${i}`"
        :key="option.value"
        :class="{
          'select-option': true,
          selected: isSelected(option),
        }"
        role="option"
        :tabindex="open? 0: -1"
        :aria-selected="isSelected(option)"
        @click="!Array.isArray(modelValue) && !option.disabled && selectValue(option)"
        @keyup.enter.stop="!option.disabled && selectValue(option)"
        @keyup.space.stop="!option.disabled && selectValue(option)"
        @keyup.down="$event.target.nextElementSibling?.focus()"
        @keyup.up="$event.target.previousElementSibling?.focus()"
      >
        <img v-if="option.icon" :src="option.icon" :alt="option.label" class="h-[30px] mr-2" tabindex="-1"/>
        <Checkbox
          v-if="multiple"
          :modelValue="Array.isArray(modelValue) && modelValue.includes(option.value)"
          :aria-selected="isSelected(option)"
          :disabled="option.disabled"
          @update:modelValue="selectValue(option)"
        />
        <span :class="{'text-gray-500': option.disabled}">{{ option.label }}</span>
      </div>
    </div>
  </div>
  <div v-else>
    <CGInput :id="id" readonly :modelValue='text' />
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import { popperGenerator } from '@popperjs/core/lib/popper-base';
import offset from '@popperjs/core/lib/modifiers/offset';
import popperOffsets from '@popperjs/core/lib/modifiers/popperOffsets';
import computeStyles from '@popperjs/core/lib/modifiers/computeStyles';
import applyStyles from '@popperjs/core/lib/modifiers/applyStyles';
import flip from '@popperjs/core/lib/modifiers/flip';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';
import eventListeners from '@popperjs/core/lib/modifiers/eventListeners';

export default defineComponent({
  props: {
    modelValue: {
      type: [String, Number, Array],
      default: undefined,
    },
    options: {
      type: Array,
      default() {
        return []
      },
    },
    id: {
      type: [String, Number],
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    context: {
      type: String,
      default: undefined,
      required: false
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    placement: {
      type: String,
      default: 'bottom-start',
    },
    errorMessage: {
      type: String,
      default: undefined,
    },
    errored: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    autoWidth: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showFilter: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false
    },
    erasable : {
      type: Boolean,
      default: false
    },
    showPlaceholder: {
      type: Boolean,
      default: false
    },
    maximumWidth: {
      type: Boolean,
      default: false
    },
  },

  emits: ['input', 'update:modelValue'],

  data() {
    return {
      open: false,
      popper: null,
      filterValue: '',
      filterWidth: null,
      maxWidthValue: null
    }
  },

  computed: {
    text() {
      // if there is no value set return the first element label if the value is null/empty string. Those options are normally the default values.
      if (!this.modelValue && this.modelValue != 0) return this.options.length && !this.options[0].value? this.options[0].label : '';
      if (this.multiple && !Array.isArray(this.modelValue)) return '';
      const opts = this.multiple
        ? this.options.filter((opt) => this.modelValue.includes(opt.value))
        : [this.options.find((opt) => opt.value === this.modelValue)].filter(Boolean);
      return opts.map((opt) => opt.label).join(', ');
    },
    filteredOptions() {
      return this.filterValue? this.options.filter((option) => option.label && (option.label+'').toLowerCase().includes(this.filterValue.toLowerCase())) : this.options;
    },
  },

  watch: {
    open(isOpen) {
      if (isOpen) {
        document.documentElement.addEventListener('click', this.handleClickOutside.bind(this), { capture: true })
      } else {
        document.documentElement.removeEventListener('click', this.handleClickOutside.bind(this))
      }
    },
  },

  mounted() {
    nextTick(() => {
      // Validate refs before creating Popper instance
      const selectEl = this.$refs.selectEl;
      const selectMenuEl = this.$refs.selectMenuEl;

      if (selectEl && selectMenuEl) {
        const createPopper = popperGenerator({
          defaultModifiers: [offset, popperOffsets, computeStyles, applyStyles, flip, preventOverflow, eventListeners],
        });

        // Create Popper instance with proper references
        this.popper = createPopper(selectEl, selectMenuEl, {
          placement: this.placement,
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                padding: 8
              }
            },
            {
              name: 'offset',
              options: {
                offset: [0, 8]
              }
            }
          ]
        });

        // Event listener for 'loading' event
        this.$eventBus.$on('loading', value => {
          setTimeout(() => {
            if (this.popper && !value) {
              try {
                this.popper.update(); // Only call update if popper is initialized
              } catch (error) {
                console.error("Error updating popper: ", error);
              }
            }
          }, 50);
        });

        this.filterWidth = selectEl.offsetWidth;
        const maxValue = Math.max(this.maxWidthValue, selectEl.offsetWidth);
        this.maxWidthValue = Math.max(maxValue || 0, selectEl.offsetWidth);
      } else {
        console.warn('Popper initialization failed. Invalid refs:', selectEl, selectMenuEl);
      }
    });
  },

  methods: {
    toggleOpen(value) {
      if (!this.disabled) this.open = value;
    },
    handleClickOutside(e) {
      if (!this.$refs.selectEl) return;
      if (this.$refs.selectMenuEl.contains(e.target)) return;
      if (!this.$refs.selectEl.contains(e.target)) this.toggleOpen(false);
      if (this.open && this.showFilter) this.$refs.selectFilterEl.focus();
      this.filterValue = '';
    },
    selectValue(opt) {
      if (!this.multiple) {
        this.$emit('update:modelValue', opt.value);
        this.toggleOpen(false);
      } else {
        const values = (this.modelValue) ? [...this.modelValue] : [];
        const index = values.indexOf(opt.value);
        if (index >= 0) {
          values.splice(index, 1);
        } else {
          values.push(opt.value);
        }
        this.$emit('update:modelValue', values);
      }
    },
    isSelected(option) {
      if (!this.modelValue) return false;
      return this.multiple ? this.modelValue.includes(option.value) : this.modelValue === option.value;
    },
    onFocusOut(event) {
      if (!this.$refs.selectEl.contains(event.relatedTarget) && !this.$refs.selectMenuEl.contains(event.relatedTarget))
        this.toggleOpen(false);
    }
  },
});
</script>

<style lang="scss" scoped>
.select-wrapper {
   @apply relative;
}
.select {
  @apply appearance-none order-2;
  @apply flex items-center flex-1 overflow-hidden;
  @apply h-10 px-2 rounded-none;
  @apply text-sm text-primary-700 placeholder:text-primary-300;
  outline: none;
  box-shadow: none;
  .disabled & {
    @apply bg-primary-100 text-primary-300 cursor-not-allowed;
  }
  .hide {
    clip: rect(0, 0, 0, 0);
    @apply absolute;
    @apply w-px h-px;
    @apply p-0;
    @apply -m-px;
    @apply overflow-hidden;
    @apply whitespace-nowrap;
    @apply border-0;
  }
  select {
    @apply hidden;
  }
}
.select-value {
  @apply pointer-events-none;
  @apply whitespace-nowrap text-ellipsis overflow-hidden;
  @apply -mb-0.5; // font adjustment
}
.select-menu {
  @media (min-width: 1024px) {
    & {
      position: fixed !important;
    }
  }
  @apply w-auto;
  @apply min-w-[100px];
  @apply max-h-48 overflow-y-auto z-20;
  @apply overflow-x-hidden;
  @apply bg-extra-light;
  @apply shadow-md shadow-violet-500/20;
  @apply opacity-0 scale-y-95 origin-top pointer-events-none;
  &.open {
    @apply opacity-100 scale-y-100 pointer-events-auto;
  }
  &::-webkit-scrollbar {
    @apply appearance-none;
    width: 0.375rem !important;
    @apply invisible;
  }
  &::-webkit-scrollbar-track {
    @apply bg-transparent;
    @apply rounded-sm;
    @apply my-3;
  }
  &::-webkit-scrollbar-thumb {
    @apply rounded-sm;
    @apply bg-primary-300/50;
  }
  & {
    scrollbar-width: thin;
    scrollbar-color: rgb(131 135 154 / 0.5) rgb(131 135 154 / 0.5);
    @apply scroll-my-3
  }
}
.select-option {
  @apply flex items-center w-full;
  @apply w-full text-left text-sm;
  @apply px-3.5 py-1.5;
  @apply cursor-default;
  @apply hover:bg-soft-blue-300;
  span {
    @apply -mb-1; // font adjustment
    @apply whitespace-nowrap text-ellipsis overflow-hidden;
  }
  &.selected {
    @apply text-violet-700 font-bold;
  }
  .select-menu:not(.v-auto-width):not(.v-maximum-width) & {
    @apply min-w-[19rem];
  }
}
</style>
<style lang="scss" global>
.select-wrapper {
  &.disabled {
    .icon {
      @apply text-primary-300;
    }
  }
}
</style>