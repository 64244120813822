import { DateTime } from 'luxon';
import sanitizeHtml from 'sanitize-html';
import { RiskGroup, TemplateType } from '@/common/constants';
import { useI18n } from 'vue-i18n';

const filters = {
  date(timestamp) {
    if (!timestamp) return '';

    const { locale } = useI18n();
    let date = DateTime.fromFormat(timestamp, 'yyyy-MM-dd HH:mm:ss');
    if (!date.isValid) date = DateTime.fromFormat(timestamp, 'yyyy-MM-dd');

    return new Intl.DateTimeFormat(locale.value, {
      day: 'numeric',
      month: '2-digit',
      year: 'numeric',
    })
      .format(date)
      .replace(/[ /]/g, '.');
  },

  time(val) {
    const date = new Date(val);
    if (isNaN(+date)) return '';
    
    const { locale } = useI18n();
    return new Intl.DateTimeFormat(locale.value, {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(date);
  },

  seconds(seconds) {
    seconds = parseInt(`${seconds || 0}`);

    const hours = +(seconds / 3600).toFixed(1);
    const minutes = Math.floor(seconds / 60);

    const value = hours >= 1 ? hours : minutes || seconds;
    const label = hours >= 1 ? 'ore' : minutes ? 'min' : 'sec';

    return `${value} ${label}`;
  },

  campaignDate(date) {
    if (!date) return '';

    const cDate = DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
    const cTime = DateTime.fromISO(date).toLocaleString(DateTime.TIME_24_SIMPLE);
    return cDate + ' h ' + cTime;
  },

  diffDate(startDateIso, endDateIso) {
    if (!startDateIso || !endDateIso) return '';
    return DateTime.fromISO(endDateIso)
      .diff(DateTime.fromISO(startDateIso), ['days', 'hours', 'minutes'])
      .toObject().days;
  },

  riskGroup(rg) {
    let res = 'N/A';
    for (const k of Object.keys(RiskGroup)) {
      if (rg == RiskGroup[k]) res = k;
    }
    return res;
  },

  templateType(type) {
    let res = 'N/A';
    for (const k of Object.keys(TemplateType)) {
      if (type == TemplateType[k]) res = k;
    }
    return res;
  },

  uppercase(text) {
    return text.toUpperCase();
  },

  capitalize(text) {
    return text && text.charAt(0).toUpperCase() + text.slice(1);
  },

  lowercase(text) {
    return text.toLowerCase();
  },

  integer(number) {
    return number ?? 0;
  },

  sanitizeRawHtml(rawHTML, customOptions = {}) {
    const regexpLength = [/^inherit$/, /^-?\d+(.\d+)?(?:px|em|rem|pt|%)?$/];
    const regexpLengthRepeated = [/^auto$/, /^(-?\d+(.\d+)?(?:px|em|rem|pt|%)?\s*){1,4}$/];
    const regexpColor = [/none|white|red|blue|black/, /^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/];
      
    const sanitizeOption = {
        allowedTags: ["body", "img", "head", "table", "tbody", "tr", "td", "b", "a", "span", "br", "html", "strong", "div", "p", "ol", "ul", "li"].concat(customOptions),
        allowedAttributes: {
            "img":   ["src", "style", "id", "border", "vspace", "hspace", "alt", "title", "height"],
            "html":  ["style"],
            "body":  ["style", "topmargin", "rightmargin", "bottommargin", "leftmargin", "marginwidth", "marginheight", "width", "height"],
            "table": ["style", "width", "align", "border", "cellpadding", "cellspacing", "class", "id", "bgcolor"],
            "td":    ["style", "class", "align", "valign", "bgcolor"],
            "a":     ["name", "style", "href"],
            "head":  [],
            "tbody": [],
            "tr":    [],
            "b":     [],
            "span":  [],
            "br":    [],
            "div":   ["style"],
        },
        allowedStyles: {
            '*': {
                "overflow-x": [/^visible$/, /^hidden$/, /^scroll$/, /^auto$/, /^initial$/, /^inherit$/],
                "margin": regexpLengthRepeated,
                "margin-left": regexpLengthRepeated,
                "margin-right": regexpLengthRepeated,
                "margin-top": regexpLengthRepeated,
                "margin-bottom": regexpLengthRepeated,
                "padding": regexpLengthRepeated,
                "padding-left": regexpLengthRepeated,
                "padding-right": regexpLengthRepeated,
                "padding-top": regexpLengthRepeated,
                "padding-bottom": regexpLengthRepeated,
                "width": regexpLength,
                "height": regexpLength,
                "-webkit-font-smoothing": [/^antialiased$/],
                "text-size-adjust": regexpLength,
                "-ms-text-size-adjust": regexpLength,
                "-webkit-text-size-adjust": regexpLength,
                "line-height": regexpLength,
                "mso-table-lspace": regexpLength,
                "mso-table-rspace": regexpLength,
                "border-collapse": [/^separate$/, /^collapse$/, /^initial$/, /^inherit$/],
                "border-spacing": regexpLength,
                "border": regexpLength,
                "outline": regexpColor,
                "text-decoration": [/^none$/],
                "background-color": regexpColor,
                "display": [/^inline$/, /^block$/, /^contents$/, /^flex$/, /^grid$/, /^inline-block$/, /^inline-flex$/, /^inline-grid$/, /^inline-table$/, /^list-item$/, /^run-in$/, /^table$/, /^table-caption$/, /^table-column-group$/, /^table-header-group$/, /^table-footer-group$/, /^table-row-group$/, /^table-cell$/, /^table-column$/, /^table-row$/, /^none$/, /^initial$/, /^inherit$/],
                "color": regexpColor,
                "border-radius": regexpLengthRepeated,
                "text-align": [/^left$/, /^right$/, /^center$/],
                "max-width": regexpLength,
                "min-width": regexpLength,
                "font-size": regexpLength,
                "font-weight": regexpLength.concat([/^bold$/]),
                "font-family": [/^([a-z]+-?\s?)+$/],
                "float": [/^none$/, /^left$/, /^right$/, /^initial$/, /^inherit$/],
                "max-height": regexpLength,
                "position": [/^static$/, /^absolute$/, /^fixed$/, /^relative$/, /^sticky$/, /^initial$/, /^inherit$/],
                "top": regexpLength,
                "left": regexpLength,
                "right": regexpLength,
                "bottom": regexpLength,
                "background-image": [/url\((['"])?(.*?)\1\)/g, /^none$/, /^initial$/, /^inherit$/],
                "background-position": [/(top|bottom|left|right|center|[\d.]+(?:px|em|%)|calc\(.+\))+/g], 
                "background-repeat": [/^repeat$/, /^repeat-x$/, /^repeat-y$/, /^no-repeat$/, /^initial$/, /^inherit$/], 
                "background-size": [/(auto|cover|contain|[\d.]+(?:px|em|%)|calc\(.+\))(?:\s+(auto|cover|contain|[\d.]+(?:px|em|%)|calc\(.+\)))?/g]
            }
        },
        disallowedTagsMode: 'discard',
        selfClosing: [ 'img', 'br', 'hr' ],
        allowedSchemes: sanitizeHtml.defaults.allowedSchemes.concat([ 'data'])
    }

    return sanitizeHtml(rawHTML, { ...sanitizeOption, ...customOptions });
  }
};

export default filters;