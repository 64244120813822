<template>
  <AnalyticsCard layout="vertical" :title="$t('Dashboard.Charts.ClickRateByCampaign')" :chart-height="chartHeight" :showLoader="isLoading">
    <template #chart>
      <BarChart
        v-if="!isLoading && clickRateByCampaign.datasets?.length && clickRateByCampaign.datasets[0].data.length"
        :datasets="clickRateByCampaign.datasets"
        :labels="clickRateByCampaign.labels"
        show-x-border
        show-y-labels
        show-x-labels
        show-x-intra-lines
        show-y-intra-lines
        :chart-options="options"
        :aspect-ratio="aspectRatio"
      />
      <div v-if="!isLoading && clickRateByCampaign.datasets?.length && !clickRateByCampaign.datasets[0].data.length"
        class="!min-h-[13rem] flex font-bold text-xl">
        <div class="m-auto"> <Txt bold>{{ $t('Dashboard.Charts.NoData') }}</Txt> </div>
      </div>
    </template>
    <template v-if="isAdmin" #menu-items>
      <MenuItem @click="showAvgDiff('click-rate')">{{$t('Dashboard.Charts.ClickRateByCampaign')}}</MenuItem>
      <MenuItem @click="showAvgDiff('ml-vs-rand')">{{$t('Dashboard.Charts.MlEfficiency')}}</MenuItem>
    </template>
  </AnalyticsCard>
</template> 

<script>
import phishingService from '@/services/phishing.service.js'
import colors from '@/utils/colors'
import { Roles } from '@/common/constants'
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      isLoading: false,
      title: this.$t("Dashboard.Charts.ClickRateByCampaign"),
      options: {},
      averageDifficultyDataset: {},
      clickRateByCampaign : {},
      counter: 0
    }
  },

  computed: {
    isAdmin() {
      return this.$profile.hasRoles(Roles.Admin);
    }
  },

  props: {
    chartHeight : Number,
    aspectRatio: Number, 
    apiFilter: Object,
    companyData: Object
  },

  watch: {
    '$props.apiFilter': {
      handler() {
        this.transformData()
      },
      deep: true
    }
  },

  mounted() {        
    this.transformData();
  },

  methods : {
    initProps() {
      const createDataset = (type, label, backgroundColor, borderColor, chartMode) => ({
        type,
        label,
        backgroundColor,
        borderColor,
        borderWidth: 2,
        fill: false,
        data: [],
        sent: [],
        clicked: [],
        avg_difficulty: [],
        efficiency: [],
        chartMode,
        options: {
          responsive: true,
          maintainAspectRatio: false
        },
        yAxisID: 'y1',
        spanGaps: true,
        hidden: false,
      });

      this.averageDifficultyDataset = createDataset('line', '', colors['green'][500], colors['green'][700], 'avg-diff');
      this.efficiencyDataset = createDataset('line', '', colors['accent'][500], colors['accent'][700], 'avg-rand');
      
      this.clickRateByCampaign = {
        datasets: [
          {
            label: '',
            chartMode: 'click-rate',
            backgroundColor: colors['soft-blue'][500],
            data: [],
            sent: [],
            clicked : [],
            avg_difficulty : [],
            period: [],
            hidden: false
          }
        ],
        labels: [],
        yAxisID: 'y',
      };

      this.setOptions();
    },
    showAvgDiff(showVal) {
      const isClickRate = showVal === 'click-rate';
      this.clickRateByCampaign.datasets.forEach((dataset) => {
        dataset.hidden = dataset.chartMode !== 'click-rate' ? isClickRate : !isClickRate;
      });

      this.$forceUpdate();
    },
    async transformData() {
      try {
        this.counter++;
        
        this.isLoading = true;
        const result = await phishingService.getCompanySummary(this.companyData.company_id, { code: 'campaign', ...this.apiFilter });
        const campaigns = [...result.data].slice().reverse();

        this.initProps();
    
        for(const c of campaigns) {
          this.clickRateByCampaign.labels.push(c.name)
          this.clickRateByCampaign.datasets[0].data.push(((c.clicked/c.sent)*100).toFixed(1))
          this.clickRateByCampaign.datasets[0].sent.push(c.sent)
          this.clickRateByCampaign.datasets[0].clicked.push(c.clicked)
          this.clickRateByCampaign.datasets[0].avg_difficulty.push(c.avg_difficulty)
          let start = this.$dateTime.fromFormat(c.planned_start, 'yyyy-MM-dd HH:mm:ss').toFormat('dd.MM.yyyy');
          let end = this.$dateTime.fromFormat(c.planned_end, 'yyyy-MM-dd HH:mm:ss').toFormat('dd.MM.yyyy');
          this.clickRateByCampaign.datasets[0].period.push(start + " - " + end);

          this.averageDifficultyDataset.data.push(c.avg_difficulty)
          this.averageDifficultyDataset.sent.push(c.sent)
          this.averageDifficultyDataset.clicked.push(c.clicked)
          this.averageDifficultyDataset.avg_difficulty.push(c.avg_difficulty)

          // Efficiency Dataset
          let efficiency = 0;
          if(c.avg_probability_not_random === 0.1) { 
            efficiency = null;
          } else if (c.avg_probability_random && c.avg_probability_not_random) {
            efficiency = ((c.avg_probability_not_random / c.avg_probability_random) - 1) * 100;
          }

          this.efficiencyDataset.data.push(efficiency)
          this.efficiencyDataset.sent.push(c.sent)
          this.efficiencyDataset.clicked.push(c.clicked)
          this.efficiencyDataset.hidden = this.isAdmin? false : true
          this.efficiencyDataset.efficiency.push(efficiency)
        }

        this.clickRateByCampaign.datasets.unshift(this.efficiencyDataset)
      } catch (error) {
        this.$eventBus.$emit('show-alert', {title: this.$t('General.DataFetchError'), variant: 'danger'});
      } finally {
        if (this.counter > 0) this.counter--;
        
        if (!this.counter) this.isLoading = false;
      }
    },
    formatValue(value) {
      return value? Number(value).toFixed(0) + '%' : '';
    },
    setOptions() {
      this.options = {
        scales: {
          y : {
            min: 0,
            ticks: {
              callback: value => `${value}%`
            },
          },
          y1 : {
            min: 0,
            display: true,
            position: 'right',
            ticks: {
              callback: value => `${value}%`,
              color: "black"
            },
            title: {
              color: "black"
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks : {
              label: (tooltipItem) => {
                if(tooltipItem.dataset.chartMode && tooltipItem.dataset.chartMode=='avg-diff') {
                  return this.$t('Dashboard.Charts.AverageDifficulty')+ ": "+ tooltipItem.formattedValue
                } else if(tooltipItem.dataset.chartMode && tooltipItem.dataset.chartMode=='avg-rand') {
                  return this.$t('Dashboard.Charts.AverageProbabilityNotRandom')+ ": "+ this.formatValue(tooltipItem.formattedValue)
                } else {
                  return this.$t('Dashboard.Charts.SuccessfulAttacks') + ": " + tooltipItem.formattedValue + "%";
                }
              },
              afterBody: (tooltipItem) => {
                if(tooltipItem[0].dataset.chartMode == 'click-rate') {
                  let body = []
                  // let datasetIndex = tooltipItem[0].datasetIndex;
                  let index = tooltipItem[0].dataIndex;
                  let sent = tooltipItem[0].dataset.sent[index];
                  let clicked = tooltipItem[0].dataset.clicked[index];
                  let avg_difficulty = tooltipItem[0].dataset.avg_difficulty[index];
                  let period = tooltipItem[0].dataset.period[index];
                  
                  body.push("");
                  body.push(this.$t('Dashboard.Charts.AttacksSent') + ": " + sent);
                  body.push(this.$t('Dashboard.Charts.Clicked') + ": " + clicked);
                  body.push(this.$t('Dashboard.Charts.AverageDifficulty') + ": " + Number(avg_difficulty));
                  body.push(this.$t('Dashboard.Charts.CampaignPeriod') + ": " + period);
                  
                  return body;
                }
              }
            }
          }
        }
      }
    }
  }
});
</script>