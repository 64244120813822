<template>
  <div
    :class="{
      calendar: true,
      'v-compact': compact,
      'v-bg-light': backgroundColor === 'light',
    }"
    tabindex="0"
  >
    <FullCalendar ref="calendar" :options="config" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import FullCalendarLocales from '@fullcalendar/core/locales-all';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import { mergeObjects } from '@/utils/object'

export default defineComponent({
  components: { FullCalendar },

  props: {
    date: {
      type: String, // override today as current day
      default: undefined,
    },
    backgroundColor: {
      type: String, // 'none' | 'light'
      default: 'none',
    },
    view: {
      type: String, // 'month' | 'week'
      default: 'month',
    },
    compact: {
      type: Boolean,
      default: false,
    },
    calendarOptions: {
      type: Object,
      default: undefined,
    },
    events: {
      type: Array, // Array<{ date: string, label: string, color?: string }>
      default: () => [],
    },
  },

  emits: ['date-click', 'event-click', 'event-drop', 'prev-btn-click', 'next-btn-click'],

  data() {
    return {}
  },

  computed: {
    parsedDate() {
      const date = new Date(this.date)
      if (isNaN(+date)) return undefined
      else return date
    },
    config() {
      return mergeObjects(
        {
          locales: FullCalendarLocales,
          locale: this.$i18n.locale,
          plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
          initialView: 'dayGridMonth',
          headerToolbar: {
            start: this.compact ? 'title,prev,next' : 'title,today,prev,next',
            end: this.compact ? undefined : 'dayGridMonth,timeGridWeek',
          },
          customButtons: { 
            prev: { // this overrides the prev button
              click: () => {
                const calendarApi = this.$refs.calendar.getApi();
                calendarApi.prev();
                this.$emit('prev-btn-click', calendarApi)
              }
            },
            next: { // this overrides the next button
              click: () => {
                const calendarApi = this.$refs.calendar.getApi();
                calendarApi.next();
                this.$emit('next-btn-click', calendarApi)
              }
            }
          },
          now: this.parsedDate,
          firstDay: 1,
          views: {
            dayGridMonth: {
              type: 'dayGrid',
              duration: { months: 1 },
            },
            timeGridWeek: {
              type: 'timeGrid',
              duration: { days: 7 },
              slotDuration: '00:30:00',
            },
          },
          fixedWeekCount: false,
          height: 'auto',
          stickyHeaderDates: false,
          events: this.events,
          dateClick: (args) => {
            this.$emit('date-click', args)
          },
          eventClick: (args) => {
            this.$emit('event-click', args)
          },
          eventDrop: (args) => {
            this.$emit('event-drop', args)
          }
        },
        this.calendarOptions
      )
    },
  },

  watch: {
    date() {
      const calendarApi = this.$refs.calendar.getApi()
      calendarApi.setOption('now', this.parsedDate)
      calendarApi.destroy()
      calendarApi.render()
    },
  },

  methods: {},
});
</script>

<style lang="scss" scoped>
.calendar {
  @apply p-3;

  &.v-bg-light {
    @apply bg-extra-light;
    @apply shadow-md shadow-soft-blue-500/20;
  }
}
</style>

<style lang="scss" global>
.fc {
  .fc-scrollgrid-section-sticky > * {
    @apply z-0;
  }

  .fc-daygrid-body {
    @apply z-0;
  }

  .fc-header-toolbar {
    .fc-toolbar-chunk {
      &:first-child {
        > * {
          @apply flex items-center;
        }
      }
    }

    .fc-toolbar-title {
      @apply text-sm font-bold;
      @apply mr-3;
      @apply -mb-1; // font adjustment
    }

    .fc-button {
      @apply h-6 py-0 rounded-none;
      @apply inline-flex items-center justify-center;
      @apply text-sm;
      @apply pt-0.5; // font adjustment
      @apply transition-colors duration-200 ease-in-out;
      @apply border-primary-100;
      @apply bg-transparent;
      @apply text-accent-500;
      @apply active:bg-extra-light active:text-accent-500 active:border-primary-100;
      @apply hover:bg-extra-light;

      &:not(:disabled):active,
      &:not(:disabled):focus {
        box-shadow: none;
      }
    }

    .fc-button-group {
      .fc-button + .fc-button {
        @apply ml-0;
      }
    }

    .fc-today-button {
      @apply mr-2;
    }

    .fc-prev-button,
    .fc-next-button {
      @apply w-6 ml-1 pt-0;
    }

    .fc-today-button,
    .fc-dayGridMonth-button,
    .fc-timeGridWeek-button {
      @apply text-primary-700 bg-transparent;
      @apply border-primary-100;
      @apply text-sm;

      &.fc-button-active {
        @apply bg-accent-700 border-accent-700 text-white;
      }
    }
  }

  .fc-scrollgrid {
    @apply border-0;

    th {
      @apply border-r-0;
      @apply bg-transparent;
    }
    td {
      @apply border-soft-blue-100;

      &:last-child {
        @apply border-r-0;
      }
    }
    tr {
      &:last-child {
        td {
          @apply border-b-0;
        }
      }
    }
  }

  .fc-timegrid-slot {
    @apply text-sm text-primary-300;
  }

  .fc-col-header {
    border-bottom-style: hidden;
  }

  .fc-col-header-cell {
    @apply border-0;
    @apply text-xs font-normal;
  }

  .fc-timegrid-axis {
    @apply text-xs;
  }

  .fc-timegrid-col {
    &.fc-day-today {
      @apply bg-extra-light;
    }
  }

  .fc-daygrid-day {
    &.fc-day-today {
      @apply bg-transparent;

      .fc-daygrid-day-top {
        @apply font-bold text-violet-500;
      }
    }
  }

  .fc-daygrid-day-frame {
    @apply flex flex-col justify-center;
    @apply h-full min-h-[80px];
    @apply relative p-1;
    @apply cursor-pointer;
    @apply transition-colors duration-200 ease-in-out;
    @apply hover:bg-soft-blue-300;

    .calendar.v-compact & {
      @apply min-h-[48px];
    }

    .fc-daygrid-day-top {
      @apply justify-center;
      @apply text-lg text-primary-700/70;

      .calendar.v-compact & {
        @apply text-xs;
      }
    }
  }

  .fc-daygrid-body {
    .calendar.v-compact & {
      @apply bg-white;
    }
  }

  .fc-daygrid-body-natural {
    .fc-daygrid-day-events {
      @apply my-0;

      .fc-daygrid-day-bottom {
        @apply p-0;
      }
    }
  }

  .fc-daygrid-body-unbalanced {
    .fc-daygrid-day-events {
      @apply min-h-0;
    }
  }

  .fc-daygrid-day-events {
    .calendar.v-compact & {
      @apply flex flex-wrap items-center justify-center;
    }
  }

  .fc-daygrid-event {
    @apply text-xs text-center font-medium;
    @apply rounded-lg;

    .calendar.v-compact & {
      @apply w-1.5 h-1.5 rounded-full;
      @apply p-0;

      > * {
        @apply hidden;
      }
    }

    .fc-event-title {
      @apply py-1;
      @apply -mb-0.5; // font adjustment
    }
  }
}
</style>
