import { createApp } from 'vue';
import i18n from './plugins/i18n'

import { DateTime, Duration } from 'luxon'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue'
import { eventBus } from './plugins/eventBus'
import filters from './plugins/filters'
import methods from './common/methods'
import isoCountriesLanguages from 'iso-countries-languages_local'
import router from '@/router/router'

import Accordion from "@/components/atoms/Accordion.vue";
import Alert from '@/components/atoms/Alert'
import Badge from '@/components/atoms/Badge'
import Button from '@/components/atoms/Button'
import Banner from '@/components/atoms/Banner'
import CategoryLabel from '@/components/atoms/CategoryLabel'
import Card from '@/components/atoms/Card'
import Calendar from '@/components/atoms/Calendar'
import CardsGrid from '@/components/atoms/CardsGrid'
import Checkbox from '@/components/atoms/Checkbox'
import Chip from "@/components/atoms/Chip.vue";
import CircularProgress from '@/components/atoms/CircularProgress'
import LvColorpicker from 'lightvue/color-picker';
import LvProgressBar from 'lightvue/progress-bar';
import ContextualNavigation from '@/components/atoms/ContextualNavigation'
import ContextualNavigationItem from '@/components/atoms/ContextualNavigationItem'
import CTA from '@/components/atoms/CTA'
import DatePicker from '@/components/atoms/DatePicker'
import FormGroup from '@/components/atoms/FormGroup'
import Icon from '@/components/atoms/Icon'
import IconStatus from '@/components/atoms/IconStatus'
import Input from '@/components/atoms/Input'
import InputWrapper from '@/components/atoms/InputWrapper'
import Link from '@/components/atoms/Link'
import ListSelect from '@/components/atoms/ListSelect'
import Loader from '@/components/atoms/Loader'
import Support from '@/components/atoms/Support.vue'
import Menu from '@/components/atoms/Menu'
import MenuItem from '@/components/atoms/MenuItem'
import MenuToggleButton from '@/components/atoms/MenuToggleButton'
import Modal from '@/components/atoms/Modal'
import Navbar from '@/components/atoms/Navbar.vue'
import NavbarItem from '@/components/atoms/NavbarItem.vue'
import Notifications from '@/components/atoms/Notifications'
import PageNav from '@/components/atoms/PageNav'
import PageNavItem from '@/components/atoms/PageNavItem'
import Pagination from '@/components/atoms/Pagination'
import ProfileMenu from '@/components/atoms/ProfileMenu'
import Progress from '@/components/atoms/Progress'
import Radio from '@/components/atoms/Radio'
import RankingList from '@/components/atoms/RankingList'
import SimpleSelect from '@/components/atoms/SimpleSelect'
import Select from '@/components/atoms/Select'
import Status from '@/components/atoms/Status'
import Table from '@/components/atoms/Table'
import TableCell from '@/components/atoms/TableCell'
import TableRow from '@/components/atoms/TableRow'
import Textarea from '@/components/atoms/Textarea'
import Txt from '@/components/atoms/Txt'
import TodoList from '@/components/atoms/TodoList'
import TodoListItem from '@/components/atoms/TodoListItem'
import Toggle from '@/components/atoms/Toggle'
import Tooltip from '@/components/atoms/Tooltip'
import InputTags from '@/components/atoms/InputTags'
import Tabs from '@/components/atoms/Tabs'
import Tag from '@/components/atoms/Tag'

import Logo from '@/components/assets/Logo'
import LogoSymbol from '@/components/assets/LogoSymbol'

import CyberRiskCard from '@/components/cards/CyberRiskCard'
import ModuleCard from '@/components/cards/ModuleCard'
import TemplateCard from '@/components/cards/TemplateCard'
import LandingPageCard from '@/components/cards/LandingPageCard'
import ProgressCard from '@/components/cards/ProgressCard'
import RiskRatingCard from '@/components/cards/RiskRatingCard'
import RemediationWidget from '@/components/cards/RemediationWidget'
import RemediatedWidget from '@/components/cards/RemediatedWidget'
import PlaceholderEmptyChartCard from '@/components/cards/PlaceholderEmptyChartCard'

import BarChart from '@/components/charts/BarChart'
import BCMockUp from '@/components/charts/BCMockUp'
import LineChart from '@/components/charts/LineChart'
import PCMockUp from '@/components/charts/PCMockUp'
import PieChart from '@/components/charts/PieChart'
import RadarChart from '@/components/charts/RadarChart'
import MockedCyberChainStrength from '@/components/charts/dashboard/CCSMockUp'
import CSMockUp from '@/components/charts/dashboard/CSMockUp'
import CgBarChart from '@/components/charts/dashboard/CgBarChart'
import CgLineChart from '@/components/charts/dashboard/CgLineChart'
import WeakUserRate from "@/components/charts/dashboard/WeakUserRate";
import ReliabilityScore from "@/components/charts/dashboard/ReliabilityScore";
import RiskByLocation from "@/components/charts/dashboard/RiskByLocation";
import RiskEvolution from "@/components/charts/dashboard/RiskEvolution";
import RiskByTags from "@/components/charts/dashboard/RiskByTags";

import ClickRateByCampaign from "@/components/charts/analytics/ClickRateByCampaign";
import AnalyticsPie from "@/components/charts/analytics/AnalyticsPie";
import NestedPie from "@/components/charts/analytics/NestedPie";
import ClickRateByTag from "@/components/charts/analytics/ClickRateByTag";
import ClickersByTag from "@/components/charts/analytics/ClickersByTag";
import ClickRateByTemplate from "@/components/charts/analytics/ClickRateByTemplate";
import ClickRateByTarget from "@/components/charts/analytics/ClickRateByTarget";
import ClickRateByOrgHeatmap from '@/components/charts/analytics/ClickRateByOrgHeatmap';
import GlobalStats from '@/components/charts/analytics/GlobalStats';

import PageHead from '@/components/layout/PageHead'
import PageWrapper from '@/components/layout/PageWrapper'
import SideWrapper from '@/components/layout/SideWrapper'
import UserSidebar from '@/components/layout/UserSidebar'

import MailModal from '@/components/modals/MailModal'
import ModalForm from '@/components/modals/ModalForm'
import LangModal from '@/components/modals/LangModal'
import TemplateModal from '@/components/modals/TemplateModal'
import AnalyticsCard from '@/components/cards/AnalyticsCard'
import LandingPageModal from '@/components/modals/LandingPageModal'
import Editor from '@/components/Editor'
import PolicyModal from '@/components/PolicyModal'
import FileUploadModal from '@/components/modals/FileUploadModal'
import DateRangePicker from '@/components/atoms/DateRangePicker'

import ReportFilter from '@/components/filters/ReportFilter'
import Widget from "@/components/atoms/Widget.vue";

library.add(fas)
library.add(fab)

const app = createApp(App);

// Set global properties
let gp = app.config.globalProperties;
gp.$eventBus = eventBus;
gp.$filters = filters;
gp.$methods = methods;
gp.$dateTime = DateTime;
gp.$duration = Duration;
gp.$isoCountriesLanguages = isoCountriesLanguages;

app.component('Accordion', Accordion)
app.component('Alert', Alert)
app.component('Badge', Badge)
app.component('Banner', Banner)
app.component('CGButton', Button)
app.component('CategoryLabel', CategoryLabel)
app.component('Card', Card)
app.component('Calendar', Calendar)
app.component('CardsGrid', CardsGrid)
app.component('Checkbox', Checkbox)
app.component('Chip', Chip)
app.component('CircularProgress', CircularProgress)
app.component('ColorPicker', LvColorpicker)
app.component('ProgressBar', LvProgressBar)
app.component('ContextualNavigation', ContextualNavigation)
app.component('ContextualNavigationItem', ContextualNavigationItem)
app.component('CTA', CTA)
app.component('DatePicker', DatePicker)
app.component('DateRangePicker', DateRangePicker)
app.component('FormGroup', FormGroup)
app.component('Icon', Icon)
app.component('IconStatus', IconStatus)
app.component('CGInput', Input)
app.component('InputWrapper', InputWrapper)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('CGLink', Link)
app.component('ListSelect', ListSelect)
app.component('Loader', Loader)
app.component('Support', Support)
app.component('Menu', Menu)
app.component('MenuItem', MenuItem)
app.component('MenuToggleButton', MenuToggleButton)
app.component('Modal', Modal)
app.component('Navbar', Navbar)
app.component('NavbarItem', NavbarItem)
app.component('Notifications', Notifications)
app.component('PageNav', PageNav)
app.component('PageNavItem', PageNavItem)
app.component('Pagination', Pagination)
app.component('ProfileMenu', ProfileMenu)
app.component('CGProgress', Progress)
app.component('Radio', Radio)
app.component('RankingList', RankingList)
app.component('CGSimpleSelect', SimpleSelect)
app.component('CGSelect', Select)
app.component('Status', Status)
app.component('CGTable', Table)
app.component('TableCell', TableCell)
app.component('TableRow', TableRow)
app.component('CGTextarea', Textarea)
app.component('Txt', Txt)
app.component('TodoList', TodoList)
app.component('TodoListItem', TodoListItem)
app.component('Toggle', Toggle)
app.component('Tooltip', Tooltip)
app.component('InputTags', InputTags)
app.component('Tabs', Tabs)
app.component('Tag', Tag)

app.component('Logo', Logo)
app.component('LogoSymbol', LogoSymbol)

app.component('CyberRiskCard', CyberRiskCard)
app.component('ModuleCard', ModuleCard)
app.component('TemplateCard', TemplateCard)
app.component('LandingPageCard', LandingPageCard)
app.component('ProgressCard', ProgressCard)
app.component('RiskRatingCard', RiskRatingCard)
app.component('RemediationWidget', RemediationWidget)
app.component('RemediatedWidget', RemediatedWidget)
app.component('ClickRateByOrgHeatmap', ClickRateByOrgHeatmap)
app.component('GlobalStats', GlobalStats);
app.component('PlaceholderEmptyChartCard',PlaceholderEmptyChartCard);

app.component('BarChart', BarChart)
app.component('BCMockUp', BCMockUp)
app.component('LineChart', LineChart)
app.component('PCMockUp', PCMockUp)
app.component('PieChart', PieChart)
app.component('RadarChart', RadarChart)

app.component('PageHead', PageHead)
app.component('PageWrapper', PageWrapper)
app.component('SideWrapper', SideWrapper)
app.component('UserSidebar', UserSidebar)

app.component('MailModal', MailModal)
app.component('ModalForm', ModalForm)
app.component('LangModal', LangModal)
app.component('TemplateModal', TemplateModal)

app.component('AnalyticsCard', AnalyticsCard)
app.component('MockedCyberChainStrength', MockedCyberChainStrength)
app.component('CSMockUp', CSMockUp)
app.component('CgBarChart', CgBarChart)
app.component('CgLineChart', CgLineChart)
app.component('WeakUserRate', WeakUserRate)
app.component('ReliabilityScore', ReliabilityScore)
app.component('RiskByLocation', RiskByLocation)
app.component('RiskEvolution', RiskEvolution)
app.component('RiskByTags', RiskByTags)

app.component('ClickRateByCampaign', ClickRateByCampaign)
app.component('AnalyticsPie', AnalyticsPie)
app.component('ClickRateByTag', ClickRateByTag)
app.component('ClickersByTag', ClickersByTag)
app.component('ClickRateByTemplate', ClickRateByTemplate)
app.component('ClickRateByTarget', ClickRateByTarget)
app.component('NestedPie', NestedPie)

app.component('LandingPageModal', LandingPageModal)
app.component('Editor', Editor)
app.component('PolicyModal', PolicyModal)
app.component('FileUploadModal', FileUploadModal)

app.component('ReportFilter', ReportFilter)
app.component('Widget', Widget)

app.use(router).use(i18n);
app.mount('#app');