<template>
  <div
    :class="{
      'table-wrapper': true,
      'v-adjust-padding': adjustPadding,
      'v-selection': !singleSelection && selectedRecords && selectedRecords.length > 0,
    }"
  >
    <table
      :class="{
        table: true,
        'v-alternate-white': alternateColor === 'white',
        'v-alternate-light': alternateColor === 'light',
        'size-xs': !fontSize || fontSize === 'xs',
        'size-sm': fontSize === 'sm',
        'size-md': fontSize === 'md'
      }"
    >
      <caption class="sr-only">{{ name }}</caption>
      <thead v-if="$slots.head">
        <tr>
          <TableCell v-if="selectable && !singleSelection" class="table-selection-cell" head>
            <Checkbox :modelValue="isChecked" 
            @update:modelValue="toggleSelectAll" />
          </TableCell>
          <slot name="head" />
        </tr>
      </thead>
      <tbody>
        <slot />
      </tbody>
    </table>

    <div class="table-selection-toolbar">
      <Txt v-if="selectable && !singleSelection" class="-mb-1" extra-small>{{ $t('Components.SelectedElements', selectedRecords ? selectedRecords.length : 0) }}</Txt>
      <div
        :class="{
          'table-selection-toolbar-actions': true,
          'v-grouped': groupedActions,
        }"
      >
        <slot name="selection-actions" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import Checkbox from './Checkbox.vue'
import Txt from './Txt.vue'
import TableCell from './TableCell.vue'
import TableRow from './TableRow.vue'; // Import the TableRow component

export default defineComponent({
  emits: ['update:selectedRecords'],
  components: { TableCell, Checkbox, Txt },

  props: {
    selectable: {
      type: Boolean,
      default: false,
    },
    singleSelection: {
      type: Boolean,
      default: false
    },
    selectedRecords: {
      type: Array,
      default: undefined,
    },
    records: {
      type: Array,
      default: undefined,
    },
    alternateColor: {
      type: String,
      default: undefined, // 'white' | 'light'
    },
    fontSize: {
      type: String,
      default: 'xs', // 'md'
    },
    adjustPadding: {
      type: Boolean,
      default: false,
    },
    groupedActions: {
      type: Boolean,
      default: false,
    },
    sortKey: {
      type: String,
      default: undefined,
    },
    sortDirection: {
      type: String, // 'asc' | 'desc'
      default: undefined,
    },
    name: {
      type: String,
      default: undefined
    }
  },
  computed: {
    isChecked() {
      return this.records && this.records.length > 0 && this.records.every(record => this.selectedRecords && this.selectedRecords.some(selectedRecord => selectedRecord.account_id === record.account_id));
    }
  },

  methods: {
    toggleSelectAll(checked) {
      if (!this.selectedRecords) return;

      // Call the default slot function to get the rendered VNodes
      const slotNodes = this.$slots.default ? this.$slots.default() : [];

      // Filter the VNodes for 'TableRow' components
      const selectable = slotNodes.flatMap(node => node.children || []).filter((node) => {
        return node.type === TableRow && !(node.props && node.props.disableSelection);
      });

      if (checked) {
        // Map the records only from selectable nodes
        const selectedRecords = selectable
          .map((node) => node.props.record)
          .filter((record) => record); // Filter to avoid null or undefined records

        const uniqueRecords = [ ...new Map([...selectedRecords, ...this.selectedRecords].map(record => [record.account_id, record])).values() ];
        
        this.$emit('update:selectedRecords', uniqueRecords);
      } else {
        const selectedRecords = this.selectedRecords.filter((record) => {
          return !selectable.some((node) => node.props.record.account_id === record.account_id);
        });
        this.$emit('update:selectedRecords', selectedRecords);
      }
    }
  },
});
</script>

<style lang="scss" scoped>
.table-wrapper {
  @apply relative;
  @apply overflow-x-auto overflow-y-hidden;

  &.v-adjust-padding {
    @apply -mx-2.5; // same as TableCell
  }
}

:deep(.table) {
  @apply min-w-full;

  &.size-xs {
    @apply text-xs;
  }

  &.size-sm {
    @apply text-sm;
  }

  &.size-md {
    @apply text-md;
  }

  thead {
    tr {
      th {
        @apply border-b-[1.5px] border-soft-blue-300;
        @apply transition-opacity duration-200 ease-in-out;
        /*@apply sticky top-0 bg-white z-[1];*/
      }
    }
  }

  &.v-alternate-white {
    tbody {
      .table-row:nth-child(odd) {
        @apply bg-white/50;
      }
    }
  }

  &.v-alternate-light {
    tbody {
      .table-row:nth-child(odd) {
        @apply bg-extra-light/50;
      }
    }
  }

  .v-selection & {
    thead {
      th:not(.table-selection-cell) {
        @apply opacity-0;
      }
    }
  }
}

.table-selection-toolbar {
  @apply absolute left-10 top-0;
  @apply opacity-0 -z-10;
  @apply h-11 flex items-center space-x-3;

  .v-selection & {
    @apply opacity-100 z-0;
  }
}

.table-selection-toolbar-actions {
  @apply flex items-center space-x-3;
  @apply px-2 py-1;

  &.v-grouped {
    @apply bg-extra-light;
  }
}
</style>

<style lang="scss" global>
.table-selection-toolbar-actions {
  button {
    svg {
      @apply text-primary-300;
    }
  }
}
</style>
